
body {
  margin: 0;
  font-size: 3em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
  text-align: center;
}

.App .Page {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}

.App .Page .PagePart {
  position: absolute;
  height: 100%;
  display: inline-block;
  top: 0;
}

.App .Page .PagePart div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.App .Page .PagePart.BackPart {
  color: white;
  background-color: black;
  left: 0;
  cursor: pointer;
}

.App .Page .PagePart.BackPart div {
  transform: translateY(-50%)rotate(-90deg);
  text-decoration: underline;
}